<script>
import { mapGetters } from 'vuex';

export default {
  // components: {},
  // directives: {},
  // filters: {},
  // extends: {},
  // mixins: {},
  model: {
    event: "update:opened",
    prop: "opened",
  },
  props: {
    appendTitleIcon: String,
    hideButtons: Boolean,
    hideCloseIcon: Boolean,
    hideHeader: Boolean,
    opened: Boolean,
    title: String,
  },
  data: function () {
    return {
      innerOpened: this.opened,
      resolve: () => {},
      reject: () => {},
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  watch: {
    opened(nextValue) {
      this.innerOpened = nextValue;
    },
  },
  // created() {},
  methods: {
    close() {
      this.innerOpened = false;
      this.$emit('update:opened', false);
      this.$emit('close', false);
    },
    open: function () {
      this.innerOpened = true;
      this.$emit('update:opened', true);
    },
    verFila: function () {
      this.$router.push({ name: 'listagem-relatorios-assincronos' });
    },
  },
}
</script>

<template>
    <v-dialog
        persistent
        max-width="500"
        :value="innerOpened"
        width="auto "
        @input="$emit('update:opened', $event)"
        >
      <v-card>
        <v-card-title class="justify-content-between">
          <slot name="header" v-bind="{ appendTitleIcon, title, hideCloseIcon, close }">
            <div>
                <v-icon v-if="appendTitleIcon" x-medium class="mr-3">
                {{ appendTitleIcon }}
                </v-icon>
                <span id="dialog-title">
                {{ title }}
                </span>
            </div>
            <v-btn
              v-if="!hideCloseIcon"
              class="header-close-btn mt-n1 mr-n2"
              icon
              @click="close()"
            >
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </slot>
        </v-card-title>
        <v-card-text>
          <p>A exportação do relatório entrou na fila para processamento e será enviado para o e-mail <b>{{ user.email }}</b> assim que finalizada.</p>
          <p>Para ver o progresso e baixar o relatório, veja a fila.</p>
        </v-card-text>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-btn color="secondary" class="pr-5" dark depressed @click="close()">
            <v-icon left>mdi-chevron-left</v-icon> Fechar
          </v-btn>
          <v-btn color="primary" class="px-5 ml-3" dark depressed @click="verFila()">Ver fila</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
